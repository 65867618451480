import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function Terms() {
  return (
    <Layout>
      <SEO title="Third-Party Service Providers - Compute Software"/>
      <Helmet></Helmet>
      <section className="terms-hero sub-hero jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">Third-Party Service Providers (“Subprocessors”)</h1>
          <p className="last-updated">Last updated: January 10, 2021</p>
        </div>
      </section>
      <section className="terms-content sub-general-content">
        <div className="container">
          <div className="row">
            <div className="terms col-lg-12">
             <p>
             Compute Software has retained the following companies to provide a discrete service to Compute Software for purposes of enabling a portion of the Services. These third-party service providers (each, a “subprocessor”) may perform a processing function for data provided by Customers and such data may include Personal Data under and as defined by the General Data Protection Regulation (the GDPR). These third parties are subject to contractual terms that meet the requirements of all applicable data protection regulations, including confidentiality and information security requirements that are at least as rigorous as required by the GDPR. 
             </p>
             <p>
              Amazon Web Services, Inc.<br/>
              Hosting provider and platform services<br/>
              U.S.A.<br/>
              <br/>
              Auth0, Inc.<br/>
              Authentication service<br/>
              U.S.<br/>
              <br/>
              Google, LLC<br/>
              G Suite for customer support<br/>
              U.S.A.<br/>
              <br/>
              InfluxData Inc.<br/>
              Database service<br/>
              U.S.A.<br/>
              <br/>
              Mixpanel <br/>
              Activity tracking<br/>
              U.S.A.<br/>
              <br/>
              SendGrid, Inc.<br/>
              Service email provider<br/>
              U.S.A<br/>
             </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
